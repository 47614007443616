<template>
    <div>
        <ZoneActionListControl/>
    </div>
</template>

<script>
    import ZoneActionListControl from '../widgets/zoneaction/ZoneActionListControl'

    export default {
        name: 'Dashboard',
        components: {
            ZoneActionListControl
        }
    }
</script>
